import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PersonDetailCard, /*StyledTabsNew*/ } from './styles';
// import { Tab } from "react-bootstrap";
import { DeleteEmployeeAPI, getCompanyLocation, getCompanyDepartment, deleteUserAPI, getUserRole, getUserCompanyDetails, getUser, getUserRecognitionsCount, getUserFriends, getUserPostImages, getWorkoutActivityCount, getUserBiometric, getUserWellbeingAssessmentDetails, setCompanyV2, getUserCompanyItitiatives } from '../../redux/actions';
import { connect } from 'react-redux';
import { ImageUrl } from '../../utils/constants';
import _, { isNull} from 'lodash';
import OverviewV3 from '../../components/UserProfile/overviewV3';
import { UserPosts, Waiting } from '../../components';
// import EditUserDetailsPopup from '../../components/PeopleHomeV2/EditUserDetailsPopup';
import EditAboutPopup from '../../components/UserProfile/addAboutPopup';
import UserAcheivementData from '../../components/UserProfile/userAchievement'
import MyBuddies from '../../components/PeopleHomeV2/ManageBuddies/myBuddies';
import MyPhotos from '../../components/MyPhotos'
import WellnessWellbeing from '../../components/UserProfile/wellnessWellbeing';
import HealthAndWellness from '../../components/UserProfile/healthWellness';
import ViewComparisonPopup from '../../components/UserProfile/viewComparisonPopup';
import { getAllAmigoInspirationPost, managePostLikedUsers } from '../../redux/actions/socialActions';
import {TabsStyle, TabsWrapper} from '../../components/common/commonStyles';
import { withTranslation } from 'react-i18next';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: this.props.location?.query?.tabValue ? this.props.location?.query?.tabValue : 1,
      defaultTab: this.props.location?.query?.tabValue ? this.props.location?.query?.tabValue : 1,
      id: "",
      showRolesPopup: false,
      userId: "",
      showDeleteUserPopUp: false,
      showUserPopUp: false,
      otherUserId: -1,
      companyId: props.userCompany.id || 1,
      selectedYear: new Date().getFullYear(),
      showComparisonPopUp: false,
      // filterType: "My Amigos",
      post_type: 'My Amigos',
      limit: 10,
      showBottom: this.props.location?.query?.showBottom
      // pageNumber: 1
    };
  }

  setActiveTabV2 = (active) => {
    this.setState({ selectedTab: active })
  }

  setActiveTab = (value) => {
    const { fetchAllAmigoInspirationPost, userId } = this.props;
    const { post_type, limit } = this.state;

    this.setState({
      post_type: value
      
    }, () => fetchAllAmigoInspirationPost(post_type, limit, 1, userId));
  };

  componentDidMount() {



    // Retrieve the query parameter values
    // const param1 = searchParams.get('param1');
    const { match, fetchUserRole, fetchUserCompany, fetchShoutoutCountAPI, userId, getUserFriends, fetchUserPostImages, getWorkoutActivityCount, getUserBiometric, getUserWellbeingAssessmentDetails, setCompany, userCompany, fetchAllAmigoInspirationPost, getUserCompanyItitiatives } = this.props;
    const { post_type, limit, showBottom } = this.state;
    if(showBottom){
      window.scrollTo(0,document.body.scrollHeight)
    }
    this.setState({
      id: parseInt(match?.params?.id,10),
      userId: parseInt(match?.params?.id,10),
    });
    let currentYear = new Date().getFullYear();
    // getUser();
    getCompanyLocation(parseInt(match?.params?.id,10));
    getCompanyDepartment(parseInt(match?.params?.id,10));
    fetchUserRole();
    fetchUserCompany();
    fetchShoutoutCountAPI(userId),
    getUserFriends(userId)
    fetchUserPostImages(userId);
    getWorkoutActivityCount(this.state.selectedYear);
    getUserBiometric();
    getUserWellbeingAssessmentDetails(currentYear);
    const startYear = userCompany.companyStartDate && userCompany.companyStartDate["company_start_date"];
    setCompany(userCompany.id, userCompany.name, startYear, userCompany.showBiometricStatus);
    // fetchUserPosts(userId, this.props.history);
    // fetchSocialPosts(filterType, 1);
    fetchAllAmigoInspirationPost(post_type, limit, 1, userId);
    getUserCompanyItitiatives();
  }

  loadMoreFeeds = (pageNumber) => {
    const { post_type, limit } = this.state;
    const { fetchAllAmigoInspirationPost, userId } = this.props;
    fetchAllAmigoInspirationPost(post_type,limit, pageNumber, userId)
  };

  // socialCallBack = () => {
  //   const { filterType } = this.state;
  //   const { fetchSocialPosts } = this.props;
  //   fetchSocialPosts(filterType, 1)
  // }

  deleteUserAction = (userId) => {
    const { deleteEmployeeAPI } = this.props;
    let obj = {};
    obj['user_uid'] = userId;
    deleteEmployeeAPI(obj);
    this.setState({
      selectedEmployee: null
    })
    this.onToggleDeletePopup();
  };

  userModal = () => {
    this.setState((prevState) => ({
      showUserPopUp: !prevState.showUserPopUp
    }));
  };

  comparisonModal = () => {
    this.setState((prevState) => ({
      showComparisonPopUp: !prevState.showComparisonPopUp
    }));
  };

  onToggleDeletePopup = () => {
    this.setState((prev) => ({ showDeleteUserPopUp: !prev.showDeleteUserPopUp }));
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  deleteUserPop = () => {
    const { userId } = this.state;
    const { deleteUserAPI, history } = this.props;
    let obj = {};
    obj['uid'] = userId;
    obj['inactive'] = 1;
    deleteUserAPI(obj, history);
    this.onToggleDeletePopup();
  };

  otherUserId = () => {
    const { history, userId } = this.props;
    const arr = history.location.pathname.split('/');
    if (arr[arr.length - 1] != 'profile') {
      return parseInt(arr[arr.length - 1],10);
    }
    else {
      return parseInt(userId,10);
    }
  };

  updateFeedsLike = (storedFeeds) => {
    managePostLikedUsers(storedFeeds);
  }

  render() {
    const { userRolesList, user, interest, userRecognitionCount, reward, history, friendList, userId, userPostImages, userWellnessInitiatives, dailyActivityData, workoutActivityCount, wellnessGoals, health, userBiometric, wellnessAssessment, userCompany, socialPost} = this.props;
    const { showUserPopUp, showComparisonPopUp } = this.state;
    if (_.isUndefined(user) || _.isNull(user) /*|| (!socialPosts && !errorMessage) || isUndefined(wellnessExpert) || isUndefined(activityFeeds) || isUndefined(friendRequests)*/) {
      return <Waiting />
    }
    let userRoleCount = 0;
    if (userRolesList && userRolesList.length > 0) {
      userRolesList.map((role) => {
        if (role.status === 1) {
          userRoleCount = userRoleCount + 1;
        }
      })
    }
    const currentYear = new Date().getFullYear();
    let yearArray = [];
    if (!isNull(userCompany.id)) {
      for (let i = currentYear; i >= userCompany["companyStartDate"].company_start_date; i--) {
        yearArray.push(i);
      }
    }
    return (
      <PersonDetailCard>
        <div className="rewards">
        </div>
        <div className='card-wrapper'>
          <div className='profile-img-section'>
            <img onError={this.fallBack} src={`${ImageUrl}/${user && user.profile_image}`} />
          </div>
          <div className='profile-contents'>
            <div className='username-location-wrapper'>
              <div className='user-name'>{user && user.fname} {user && user.lname}
              </div>
              <div className='icons-section'>
                {
                  user && user.department ?
                    <div className='place-icon'>
                      <img className='flag' src='/public/images/placeIcon.png' />
                      <span className='text'>{this.props.t(user.department)}</span>
                    </div>
                    : ""
                }
                <div className='location'>
                  <span>
                    <img className='flag2' src='/public/images/locationIcon.png' />
                  </span>
                  <span>{user && this.props.t(user.location)}</span>
                </div>
                {userRoleCount ? <div className='location'>
                  <span>
                    <img className='userRoleIcon' src='/public/images/PeoplePage/userRole.png' />
                  </span>
                </div> : null}
              </div>
            </div>
            <div className='points-section-wrapper'>
              <div className='points-section'>
                <div className='my-points'>
                  <div className='my-points-txt'>{this.props.t("My Points")}</div>
                  <div className='my-points-val'>{reward && reward.total}</div>
                </div>
                <div className='my-buddies'>
                  <div className='my-buddies-txt'>{this.props.t("My Buddies")}</div>
                  <div className='my-buddies-val'>{friendList && friendList.length}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabsWrapper defaultActiveKey={this.state.defaultTab} right id="styledTabs" onSelect={this.setActiveTabV2} margin="0 0 10px 0">
          <TabsStyle onClick={()=>this.setActiveTabV2(1)} active={this.state.selectedTab===1}>
            {this.props.t("Overview")}
          </TabsStyle>
          <TabsStyle onClick={()=>this.setActiveTabV2(2)} active={this.state.selectedTab===2}>
            {this.props.t("Achievement")}
          </TabsStyle>
          <TabsStyle onClick={()=>this.setActiveTabV2(6)} active={this.state.selectedTab===6}>
            {this.props.t("Wellness Wellbeing")}
          </TabsStyle>
          {this.props.companyBranding?.company_branding?.show_biometric ? <TabsStyle onClick={()=>this.setActiveTabV2(7)} active={this.state.selectedTab===7}>
            {this.props.t("Health & Wellness")}
          </TabsStyle>:null}
          {this.props.companyBranding?.company_branding?.social_feed ?<TabsStyle onClick={()=>this.setActiveTabV2(3)} active={this.state.selectedTab===3}>
            {this.props.t("Feed")}
          </TabsStyle>:null}
          {this.props.companyBranding?.company_branding?.social_feed ? <TabsStyle onClick={()=>this.setActiveTabV2(4)} active={this.state.selectedTab===4}>
            {this.props.t("Buddies")}
          </TabsStyle>:null}
          {this.props.companyBranding?.company_branding?.social_feed ?<TabsStyle onClick={()=>this.setActiveTabV2(5)} active={this.state.selectedTab===5}>
            {this.props.t("Photos")}
          </TabsStyle>:null}
        </TabsWrapper>
        {this.state.selectedTab === 1 ?
          <OverviewV3 userModal={this.userModal} user={user} interest={interest} wellnessInitiatives={userWellnessInitiatives} userRecognitionCount={userRecognitionCount} history={history} companyBranding={this.props.companyBranding}/>:
          this.state.selectedTab === 2 ?
            <UserAcheivementData companyId={this.state.companyId} history={history}/>:
            this.state.selectedTab === 6 ?
              <WellnessWellbeing user={user} dailyActivityData={dailyActivityData} workoutActivityCount={workoutActivityCount} history={history} />:
              this.state.selectedTab === 7 ?
                <HealthAndWellness userModal={this.comparisonModal} user={user} wellnessGoals={wellnessGoals} health={health} userBiometric={userBiometric} wellnessAssessment={wellnessAssessment} history={history} />:
                this.state.selectedTab === 3 ?
                  <UserPosts userPosts={socialPost} history={history}
                    loadMoreFeeds={(pageNumber) => this.loadMoreFeeds(pageNumber)}
                    // socialCallBack={this.socialCallBack}
                    setActiveTab={this.setActiveTab}               
                    updateFeedsLike={this.updateFeedsLike} 
                    activeTab={this.state.companyId === 1 ? this.state.post_type : "My Amigos"}
                  />:
                  this.state.selectedTab === 4 ?
                    <MyBuddies history={history} friendList={friendList} userId={userId} user={user} />:
                    this.state.selectedTab === 5 ?
                      <MyPhotos history={history} userPostImages={userPostImages} userId={userId} enableTrigger={true} updateFeedsLike={this.updateFeedsLike} />:null}
        {
          showUserPopUp ? <EditAboutPopup showUserModal={showUserPopUp} onClose={this.userModal} userDetail={this.props.user} /> : null
        }
        {
          showComparisonPopUp ? <ViewComparisonPopup showUserModal={showComparisonPopUp} onClose={this.comparisonModal} yearList={yearArray} /> : null
        }
      </PersonDetailCard>
    );
  }
}

UserProfile.defaultProps = {
  userCompany: { id: 1, name: '', companyStartDate: 2016 }
}

UserProfile.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  getUserRoles: PropTypes.func,
  userRolesList: PropTypes.array,
  deleteEmployeeAPI: PropTypes.func,
  locationDetails: PropTypes.array,
  departmentDetails: PropTypes.array,
  match: PropTypes.object,
  fetchUserRole: PropTypes.func,
  role: PropTypes.string.isRequired,
  deleteUserAPI: PropTypes.func,
  fetchUserCompany: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  interest: PropTypes.object,
  userWellnessInitiatives: PropTypes.array,
  userRecognitionCount: PropTypes.array,
  userId: PropTypes.number,
  reward: PropTypes.object,
  fetchShoutoutCountAPI: PropTypes.func,
  feeds: PropTypes.array.isRequired,
  friendList: PropTypes.array,
  t: PropTypes.func,
  userPostImages: PropTypes.array,
  fetchUserPostImages: PropTypes.func,
  getUserFriends: PropTypes.func,
  dailyActivityData: PropTypes.object,
  getWorkoutActivityCount: PropTypes.func,
  workoutActivityCount: PropTypes.object,
  wellnessGoals: PropTypes.object,
  health: PropTypes.array,
  getUserBiometric: PropTypes.func,
  userBiometric: PropTypes.array,
  wellnessAssessment: PropTypes.array,
  getUserWellbeingAssessmentDetails: PropTypes.func,
  userWbaWellbeing: PropTypes.object,
  setCompany: PropTypes.func.isRequired,
  selectedYearInfo: PropTypes.number,
  userPosts: PropTypes.array,
  fetchUserPosts: PropTypes.func.isRequired,
  otherDetails: PropTypes.object,
  socialPost: PropTypes.array,
  errorMessage: PropTypes.string,
  // fetchSocialPosts: PropTypes.func.isRequired,
  managePostLikedUsers: PropTypes.func,
  friendRequests: PropTypes.array.isRequired,
  fetchFriendRequests: PropTypes.func.isRequired,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  requestAccepted: PropTypes.string,
  networkError: PropTypes.string,
  fetchActivityFeeds: PropTypes.func.isRequired,
  activityFeeds: PropTypes.array,
  fetchWellnessExpert: PropTypes.func.isRequired,
  wellnessExpert: PropTypes.object,
  userFeedSuccess: PropTypes.array,
  fetchAllAmigoInspirationPost: PropTypes.func,
  getUserCompanyItitiatives: PropTypes.func,
  companyBranding: PropTypes.object,
};

const mapStateToProps = (state) => ({
  locationDetails: state.companyDashboard.companyPeopleLocation,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  role: state.auth.role,
  userCompany: state.profileData.userCompany,
  user: state.profileData.user,
  interest: state.profileData.interest,
  userWellnessInitiatives: state.profileData.userInitiatives,
  userRecognitionCount: state.profileData.userRecognitionCount,
  userId: state.profileData.userId,
  reward: state.wellnessDashboard.reward,
  friendList: state.social.friendList,
  userPostImages: state.social.userPostImages,
  dailyActivityData: state.profileData.dailyActivityData,
  workoutActivityCount: state.social.workoutActivityCount,
  wellnessGoals: state.profileData.wellnessGoals,
  health: state.profileData.health,
  userBiometric: state.profileData.userBiometric,
  wellnessAssessment: state.profileData.wellnessAssessment,
  userWbaWellbeing: state.profileData.userWbaWellbeing,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  // userPosts: state.social.userPosts,
  otherDetails: state.social.otherDetails,
  socialPost: state.social.amigoResponse,
  errorMessage: state.social.errorMessage,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
  // userFeedSuccess: state.social.userFeedSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  deleteEmployeeAPI: (obj) => dispatch(DeleteEmployeeAPI(obj)),
  getCompanyLocation: (id) => dispatch(getCompanyLocation(id)),
  getCompanyDepartment: (id) => dispatch(getCompanyDepartment(id)),
  deleteUserAPI: (obj, history) => dispatch(deleteUserAPI(obj, history)),
  fetchUserRole: () => dispatch(getUserRole()),
  fetchUserCompany: () => dispatch(getUserCompanyDetails()),
  fetchShoutoutCountAPI: (uid) => dispatch(getUserRecognitionsCount(uid)),
  getUserFriends: (id) => dispatch(getUserFriends(id)),
  fetchUserPostImages: (id) => dispatch(getUserPostImages(id)),
  getWorkoutActivityCount: (selectedYear) => dispatch(getWorkoutActivityCount(selectedYear)),
  getUserBiometric: () => dispatch(getUserBiometric()),
  getUserWellbeingAssessmentDetails: (year) => dispatch(getUserWellbeingAssessmentDetails(year)),
  setCompany: (id, name, companyStartDate, biometricStatus) => dispatch(setCompanyV2(id, name, companyStartDate, biometricStatus)),
  // fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history)),
  // fetchSocialPosts: (filterType, pageNumber) => dispatch(getSocialPosts(filterType, pageNumber)),
  managePostLikedUsers: (res) => dispatch(managePostLikedUsers(res)),
  fetchAllAmigoInspirationPost: (post_type, limit, pageNumber, uid) => dispatch(getAllAmigoInspirationPost(post_type, limit, pageNumber, uid)),
  getUserCompanyItitiatives: () => dispatch(getUserCompanyItitiatives()),

});

export default connect(mapStateToProps, mapDispatchToProps)(((withTranslation())(UserProfile)));